import { HttpErrorResponse } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { roomDTO } from 'src/app/models/RoomDTO.model'
import { DialogService } from 'src/app/services/dialog.service'
import { MangeStadisticsService } from 'src/app/services/manage-stadistics.service'
import { OpentokService } from 'src/app/services/opentok.service'
import { VideoconsultaBackendService } from 'src/app/services/videoconsulta-backend.service'
import { ClientData } from 'src/app/types/ClientData'
import { TokboxCredentials } from 'src/app/types/TokboxCredentials'
import { extracParams } from 'src/app/utils/utils'

@Component({
  selector: 'app-videoconsulta',
  templateUrl: './videoconsulta.component.html',
  styleUrls: ['./videoconsulta.component.css']
})

export class VideoconsultaComponent implements OnInit {

  @ViewChild('fullvideo', { static: false }) fullvideoElement: ElementRef<HTMLDivElement>
  @ViewChild('minivideo', { static: false }) minivideoElement: ElementRef<HTMLDivElement>

  cid: number
  isMedic: number
  clientParamsData: ClientData
  session: OT.Session
  medicArrived: boolean
  activeHangup: boolean
  showTimer: boolean = false
  showButtons: boolean = false

  constructor(
    private route: ActivatedRoute,
    private _videoconsulta: VideoconsultaBackendService,
    private _openTok: OpentokService,
    private router: Router,
    private _stadistics: MangeStadisticsService,
    private _dialog: DialogService,
    private _spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe({
      next: (params) => {
        const { conferenceId, isMedic, clientData } = extracParams(params)
        this.cid = conferenceId
        this.isMedic = isMedic
        this.clientParamsData = clientData
        this.medicArrived = this.isMedic ? true : false 
      }
    })

    this.activeHangup = this.isMedic ? false : true

    this._spinner.show()
    this._videoconsulta.checkCid(this.cid).subscribe({
      next: () => this.startVC(),
      error: (err: HttpErrorResponse) => {
        if (err.status === 400) {
          this._spinner.hide()
          this.router.navigate(['/finalizado'], { queryParams: { cid: this.cid, m: this.isMedic } })
        }
      }
    })
  }

  startVC(): void {
    this._stadistics.initStadistics(this.cid, this.isMedic)

    this._videoconsulta.getTokboxCredentials(this.cid, new roomDTO(0)).subscribe({
      next: (credentials: TokboxCredentials) => {
        this.initiOpentokSession(credentials)
      },
      error: err => {
        console.log(err)
      }
    })
  }

  initiOpentokSession({ apiKey, sessionId, token }: TokboxCredentials) {
    this.session = this._openTok.initSession({ apiKey, sessionId, token })

    this.session.on('streamCreated', (event) => this.streamCreated(event))

    this.session.on('streamDestroyed', (event) => this.streamDestroyed(event))

    this._openTok.initPublisher(this.minivideoElement.nativeElement, this.isMedic).then(() => {
      this._openTok.connect().then(() => {
        this._spinner.hide()
        this.showButtons = true
        this.showTimer = this.isMedic && true
        this._stadistics.sendStatistics('inicio', { data: this.clientParamsData }).catch(err => console.log(err))
      }).catch(err => err && console.error(err))
    }).catch((err: OT.OTError) => this.managePermissionsError(err))
  }

  streamCreated(event: OT.Event<'streamCreated', OT.Session> & { stream: OT.Stream }) {
    if (this._dialog.dialog) this._dialog.closeDialog()
    this.medicArrived = true
    this._openTok.suscribe(event.stream, this.fullvideoElement.nativeElement)
  }

  streamDestroyed(event: OT.Event<'streamDestroyed', OT.Session> & { stream: OT.Stream, reason: string }) {
    if (this.isMedic === 0) {
      if (event.reason === 'networkDisconnected') {
        this._dialog.createDialog('netM', this.cid, this.isMedic)
      } else if (event.reason === 'clientDisconnected') {
        this._spinner.show()
        this._videoconsulta.checkCid(this.cid).subscribe({
          next: () => {
            this._spinner.hide()
            this._dialog.createDialog('waitM', this.cid, this.isMedic)
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 400) {
              this._stadistics.sendStatistics('final', { streamfinished: 'Medico' }).then(() => {
                this.session.disconnect()
                this._spinner.hide()
                this.router.navigate(
                  ['/finalizado'],
                  { queryParams: { cid: this.cid, m: this.isMedic } }
                )
              }).catch(err => console.log(err))
            }
          }
        })
      }
    }

    if (this.isMedic === 1) {
      if (event.reason === 'networkDisconnected') {
        this._dialog.createDialog('netS', this.cid, this.isMedic)
      } else this._dialog.createDialog('waitS', this.cid, this.isMedic)
    }
  }

  enableHangup(enable: boolean): void {
    if (this.isMedic) this.activeHangup = enable
  }

  managePermissionsError(err: OT.OTError) {
    const error = (err as OT.OTError & { code: number })

    if (error.code === 1500) {
      this._spinner.hide()
      this.medicArrived = true
      this._dialog.createDialog('permissions')
    }
  }
}
